<template>
  <div style="margin:50px auto;display:block;">
      <p style="padding:25px 0px 25px 0px;font-size:30px;color:#333;font-weight:600;text-align:center">
          Contactos
      </p>
      <div style="margin:50px auto;display:block;width:50px;border-top:solid 4px #D41700"></div>


      <v-card flat style="background:transparent;width:80%;margin: 0 auto;display:block;" >
        <v-card-text>
          <p style="font-size:30px;font-weight:700;color:#333">
            InmoGlobal
          </p>
        </v-card-text>
        <v-card-text>
          Celular:+(58)(414)412-3718 <br>
          Teléfono:+(58)(241)825-7771 <br>
          E-mail: inmoglobalve@gmail.com <br>
        </v-card-text>
      </v-card>


      <div style="width:80%;margin:0 auto;display:block;">
          <v-btn class="mb-5" text href="https://wa.me/584144123718" target="_blank"
                style="background:#c2e2ff;width:60px;height:60px;border-radius:10px;border:solid 1px blue;">
                  <i class="fas fa-mobile blue--text" style="font-size:20px;"></i>
                </v-btn>

                <v-btn class="mx-3 mb-5" text href="https://www.instagram.com/curubasoftofficial/" target="_blank"
                style="background:#f802cf31;width:60px;height:60px;border-radius:10px;border:solid 1px #f802cff5;">
                  <i class="fab fa-instagram" style="color:#f802cff5;font-size:20px;"></i>
                </v-btn>

                <v-btn class="mb-5" text href="https://www.facebook.com/curubasoftofficial/" target="_blank"
                style="background:#006eff33;width:60px;height:60px;border-radius:10px;border:solid 1px blue;">
                  <i class="fab fa-facebook-f blue--text" style="font-size:20px;"></i>
                </v-btn>
      </div>



      <v-card flat style="border:solid 1px #eee;background:white;width:80%;margin: 0 auto;display:block;" >
        <v-card-text class="title" style="text-align:center;width:90%;margin:0 auto;display:block;">
          Contáctanos
        </v-card-text>
        <v-card-text style="width:90%;margin:0 auto;display:block;">
           <v-layout row justify-center>
             <v-flex xs12 md3>
               <span style="color:red;">Obligatorio</span><br>
               <input type="text" v-model="nombre" class="inputsfg" placeholder="Nombre & Apellido">
             </v-flex>
             <v-flex xs12 md3>
               <span style="color:red;"></span><br>
               <input type="text" v-model="dni" class="inputsfg" placeholder="Cedula o RIF">
             </v-flex>
             <v-flex xs12 md3>
               <span style="color:red;">Obligatorio</span><br>
               <input type="text" v-model="tlf" class="inputsfg" placeholder="Teléfono">
             </v-flex>
             <v-flex xs12 md3>
               <span style="color:red;">Obligatorio</span><br>
               <input type="text" v-model="email" class="inputsfg" placeholder="Correo Electrónico">
             </v-flex>
             <v-flex xs12 class="my-3">

               <textarea v-model="comentario" rows="10" placeholder="Comentario"
           style="width:100%;padding:10px 20px 10px 20px;color:#333;outline:0;border:solid 1px #D41700"
           ></textarea>

           <v-btn class="my-3" @click="alers()"
           text style="background:#00174A;color:white;font-weight:700;text-transform:none;
           padding:10px 50px 10px 50px;">
             Enviar
           </v-btn>
             </v-flex>
           </v-layout>
        </v-card-text>
      </v-card>

      <v-card flat style="border:solid 1px #eee;background:white;width:80%;margin: 50px auto;display:block;" >
        <v-card-text class="title" style="text-align:center;width:90%;margin:0 auto;display:block;line-height:25px;">
          ¿Quieres pertenecer a nuestro TeamGlobal?
        </v-card-text>
        <v-card-text style="width:90%;margin:0 auto;display:block;">
           <v-layout row justify-center>
             <v-flex xs12 md3>
               <span style="color:red;">Obligatorio</span><br>
               <input type="text" v-model="nombreNuevo" class="inputsfg" placeholder="Nombre & Apellido">
             </v-flex>
             <v-flex xs12 md3>
               <span style="color:red;">Obligatorio</span><br>
               <input type="text" v-model="dniNuevo" class="inputsfg" placeholder="Cédula o RIF">
             </v-flex>
             <v-flex xs12 md3>
               <span style="color:red;">Obligatorio</span><br>
               <input type="text" v-model="tlfNuevo" class="inputsfg" placeholder="Teléfono">
             </v-flex>
             <v-flex xs12 md3>
               <span style="color:red;">Obligatorio</span><br>
               <input type="text" v-model="emailNuevo" class="inputsfg" placeholder="Correo Electrónico">
             </v-flex>
             <v-flex xs12 class="my-3">

           <v-btn class="my-3" @click="enviarCorreo()"
           text style="background:#00174A;color:white;font-weight:700;text-transform:none;
           padding:10px 50px 10px 50px;">
             Enviar
           </v-btn>
             </v-flex>
           </v-layout>
        </v-card-text>
      </v-card>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return {
      nombreNuevo:'',dniNuevo:'',tlfNuevo:'',emailNuevo:'',
      nombre:'',dni:'',tlf:'',email:'',comentario:'',
    }
  },
  methods:{
    async alers(){
            try {
                if(this.nombre != '' || this.tlf != '' || this.dni != '' || this.email != ''){
                  await axios.post('https://inmoglobalve.com.ve/ParteTeam.php', {
                    nombreNuevo: this.nombreNuevo,
                    dniNuevo: this.dniNuevo,
                    tlfNuevo: this.tlfNuevo,
                    comentario: this.comentario,
                    emailNuevo: this.emailNuevo
                }).then(()=>{
                    alert('Se ha enviado correctamente!')
                })
                }else if(this.nombre == '' || this.tlf == '' || this.dni == '' || this.email == ''){
                  alert('Completar todos los campos!')
                }
            } catch (error) {
                console.log(error)
            }
        },
        async enviarCorreo(){
            try {
                if(this.nombreNuevo != '' || this.dniNuevo != '' || this.tlfNuevo != '' || this.emailNuevo != ''){
                  await axios.post('https://inmoglobalve.com.ve/ContactoEmail.php', {
                    nombreNuevo: this.nombreNuevo,
                    dniNuevo: this.dniNuevo,
                    tlfNuevo: this.tlfNuevo,
                    emailNuevo: this.emailNuevo
                }).then(()=>{
                    alert('Se ha enviado correctamente!')
                })
                }else if(this.nombreNuevo == '' || this.dniNuevo == '' || this.tlfNuevo == '' || this.emailNuevo == ''){
                  alert('Completar todos los campos!')
                }
            } catch (error) {
                console.log(error)
            }
        },
  }
}
</script>

<style>
.fg{
    color: #c2e2ff;
}
.inputsfg {
  padding: 15px 20px 15px 20px;color: #333;width:90%;text-align: center;font-weight:700;outline: 0;
  border:solid 1px #D41700;
  width:100%;
}

</style>